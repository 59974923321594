import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const DevelopmentStages = () => {
  return (
    <section className="lg:pt-14 md:pt-10 pt-8 lg:pb-14 md:pb-10 pb-8">
      <div className="container">
        <div className="flex justify-between items-center flex-wrap md:flex-nowrap pb-5">
          <h2 className="text-primary text-base font-gorditabold w-full mb-3 md:mb-0">
            Our website design processes.
          </h2>
        </div>
        <div className="md:mb-20 mb-10 flex items-start justify-between flex-wrap md:flex-nowrap">
          <div className="md:md:max-w-[49%] max-w-full w-full md:order-1 order-2 md:pr-6 pr-0">
            <h3 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] font-blancotrialbold md:mb-0 mb-2">
              1. Define website goals and scope
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h3>
            <p className="text-base leading-[1.688rem] text-grey">
              We start with understanding your website goals — what purpose will
              it fulfil to your business and your audience? By doing so, we can
              make the right decisions for the upcoming stages. And once we have
              the goals set, we define the scope of the website — the design &
              development deliverables and the timeline to achieve them.
            </p>
          </div>
          <div className="md:max-w-[28.75rem] max-w-full w-full md:order-2 order-1 mb-6 md:mb-0">
            <StaticImage
              src="../../../assets/img/websiteGoals.png"
              alt="Define website goals and scope"
              className="w-full"
              imgClassName="object-contain"
              placeholder="blurred"
            />
          </div>
        </div>
        <div className="md:mb-20 mb-10 flex items-start justify-between flex-wrap md:flex-nowrap">
          <div className="md:md:max-w-[49%] max-w-full w-full md:order-2 order-2 md:pl-6 pl-0">
            <h3 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] font-blancotrialbold md:mb-0 mb-2">
              2. Site architecture and wireframing
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h3>
            <p className="text-base leading-[1.688rem] text-grey">
              Here we define the sitemap — all the web pages, how these pages
              will relate to each other, and define the overall navigation of
              the website. And we create the wireframes, a low fidelity but
              detailed view of how each page would look like — the layouts and
              the placements of design elements.
            </p>
            <ul className="flex flex-wrap items-start mt-5">
              <li className="flex flex-col items-start justify-center w-16 md:mr-7 mr-4 mb-4 md:mb-0 cursor-pointer">
                <StaticImage
                  src="../../../assets/img/smileEmoji.png"
                  alt="smile emoji sketch"
                  placeholder="blurred"
                />
              </li>
              <li className="flex flex-col items-start justify-center w-16 md:mr-7 mr-4 mb-4 md:mb-0 cursor-pointer">
                <StaticImage
                  src="../../../assets/img/figma.png"
                  alt="smile emoji sketch"
                  placeholder="blurred"
                />
              </li>
              <li className="flex flex-col items-start justify-center w-16 md:mr-7 mr-4 mb-4 md:mb-0 cursor-pointer">
                <StaticImage
                  src="../../../assets/img/diamond.png"
                  alt="smile emoji sketch"
                  placeholder="blurred"
                />
              </li>
            </ul>
          </div>
          <div className="md:max-w-[28.75rem] max-w-full w-full md:order-1 order-1 mb-6 md:mb-0">
            <StaticImage
              src="../../../assets/img/siteArchitecture.png"
              alt="Site architecture and wireframing"
              className="w-full"
              imgClassName="object-contain"
              placeholder="blurred"
            />
          </div>
        </div>
        <div className="md:mb-20 mb-10 flex items-start justify-between flex-wrap md:flex-nowrap">
          <div className="md:md:max-w-[49%] max-w-full w-full md:order-1 order-2 md:pr-6 pr-0">
            <h3 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] font-blancotrialbold md:mb-0 mb-2">
              3. Design
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h3>
            <p className="text-base leading-[1.688rem] text-grey">
              Based on the blueprint created at the last stage, we start adding
              visual details to the page — color, typography, icons,
              illustrations photos, and other media. All these conform to your
              brand image and website goals identified in the early stage.
            </p>
            <ul className="flex flex-wrap items-start mt-5">
              <li className="flex flex-col items-start justify-center w-16 md:mr-7 mr-4 mb-4 md:mb-0 cursor-pointer">
                <StaticImage
                  src="../../../assets/img/figma.png"
                  alt="smile emoji sketch"
                  placeholder="blurred"
                />
              </li>
              <li className="flex flex-col items-start justify-center w-16 md:mr-7 mr-4 mb-4 md:mb-0 cursor-pointer">
                <StaticImage
                  src="../../../assets/img/diamond.png"
                  alt="smile emoji sketch"
                  placeholder="blurred"
                />
              </li>
              <li className="flex flex-col items-start justify-center w-16 md:mr-7 mr-4 mb-4 md:mb-0 cursor-pointer">
                <StaticImage
                  src="../../../assets/img/xd.png"
                  alt="smile emoji sketch"
                  placeholder="blurred"
                />
              </li>
            </ul>
          </div>
          <div className="md:max-w-[28.75rem] max-w-full w-full md:order-2 order-1 mb-6 md:mb-0">
            <StaticImage
              src="../../../assets/img/design.png"
              alt="website design"
              className="w-full"
              imgClassName="object-contain"
              placeholder="blurred"
            />
          </div>
        </div>
        <div className="md:mb-20 mb-10 flex items-start justify-between flex-wrap md:flex-nowrap">
          <div className="md:md:max-w-[49%] max-w-full w-full md:order-2 order-2 md:pl-6 pl-0">
            <h3 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] font-blancotrialbold md:mb-0 mb-2">
              4. Develop
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h3>
            <p className="text-base leading-[1.688rem] text-grey">
              After designing the pages, the next step is to bring those designs
              to life and make the website functional. We use various
              technologies, such as HTML, CSS, React, Angular, Node, Laravel and
              WordPress, depending on the goals and the scope of the website. We
              also take care of animations, responsive design, performance,
              security, and integration of the website at this stage.
            </p>
          </div>
          <div className="md:max-w-[28.75rem] max-w-full w-full md:order-1 order-1 mb-6 md:mb-0">
            <StaticImage
              src="../../../assets/img/develop.png"
              alt="website develop"
              className="w-full"
              imgClassName="object-contain"
              placeholder="blurred"
            />
          </div>
        </div>
        <div className="md:mb-20 mb-10 flex items-start justify-between flex-wrap md:flex-nowrap">
          <div className="md:md:max-w-[49%] max-w-full w-full md:order-1 order-2 md:pr-6 pr-0">
            <h3 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] font-blancotrialbold md:mb-0 mb-2">
              5. Test
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h3>
            <p className="text-base leading-[1.688rem] text-grey">
              Before the website is launched, it is placed on our production
              server where it goes through various meticulous tests. Our quality
              team reviews the website on various devices (such as laptops,
              tablets, and mobile phones) and browsers (such as Chrome, Firefox,
              Safari, and Internet Explorer) to ensure it’s bug-free and works
              optimally.
            </p>
          </div>
          <div className="md:max-w-[28.75rem] max-w-full w-full md:order-2 order-1 mb-6 md:mb-0">
            <StaticImage
              src="../../../assets/img/tested.png"
              alt="website testing"
              className="w-full"
              imgClassName="object-contain"
              placeholder="blurred"
            />
          </div>
        </div>
        <div className="mb-0 flex items-start justify-between flex-wrap md:flex-nowrap">
          <div className="md:md:max-w-[49%] max-w-full w-full md:order-2 order-2 md:pl-6 pl-0">
            <h3 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] font-blancotrialbold md:mb-0 mb-2">
              6. Launch and Maintain
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h3>
            <p className="text-base leading-[1.688rem] text-grey">
              Once the quality assurance team gives the green flag, it’s time to
              get your website live on the internet and help you achieve your
              business goals.
            </p>
          </div>
          <div className="md:max-w-[28.75rem] max-w-full w-full md:order-1 order-1 mb-6 md:mb-0">
            <StaticImage
              src="../../../assets/img/deployedSuccessfully.png"
              alt="website deployed successfully"
              className="w-full"
              imgClassName="object-contain"
              placeholder="blurred"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
export default DevelopmentStages
